define('emberfire-utils/utils/has-limited', ['exports', 'ember', 'ember-computed'], function (exports, _ember, _emberComputed) {
  exports['default'] = hasLimited;
  var ArrayProxy = _ember['default'].ArrayProxy;
  var PromiseProxyMixin = _ember['default'].PromiseProxyMixin;

  var PromiseArray = ArrayProxy.extend(PromiseProxyMixin);

  /**
   * Get the reference for the specified `hasLimited` relationship.
   *
   * The difference with native `hasMany` is that this supports
   * queries with infinite records through `firebaseUtil.next()`.
   * This will also serialize the records into a `DS.Model`.
   *
   * @param {string} modelName Model name of the records to query
   * @param {string} listenerId Firebase listener ID
   * @param {string} path Path of records in Firebase
   * @param {Object} [options] Query options
   * @return {Utility.PromiseArray} Promise array resolving to records
   */

  function hasLimited(modelName, listenerId, path, options) {
    return (0, _emberComputed['default'])({
      get: function get() {
        var newListenerId = listenerId.replace('$id', this.get('id'));
        var newPath = path.replace('$id', this.get('id'));

        return PromiseArray.create({
          promise: this.get('firebaseUtil').query(modelName, newListenerId, newPath, options)
        });
      }
    }).readOnly();
  }
});
/** @module emberfire-utils */